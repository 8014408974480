import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import logo from "assets/img/new_logo.png";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import { Mail, Lock } from "react-feather";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "test",
      password: "test",
      isSignedUp: false,
      error: false,
    };
  }

  /**
   * Redirect to root
   */
  isUserAuthenticated = () => {
    const user = localStorage.getItem("user")
    return user ? true : false;
  };
  
  /**
   * Redirect to root
   */
  renderRedirectToRoot = () => {
    const isAuthTokenValid = this.isUserAuthenticated();
    if (isAuthTokenValid) {
      return <Redirect to="/" />;
    }
  };

  /**
   * Handles the submit
   */
  handleValidSubmit = (event, values) => {
    const params = new URLSearchParams({
      co_login: values.username,
      co_password: values.password,
    }).toString();

    axios.post('https://immonaco-backend.miells.com/api-web/1.0/signin', params).then((response) => {
      if (response.data.data !== null) {
        localStorage.setItem("user", JSON.stringify(response.data))
        this.setState({ isSignedUp: true })
        this.setState({ error : false })
      } else {
        localStorage.removeItem("user")
        this.setState({ error : true })
      }
    })
  };

  render() {
    const { error } = this.state
    document.body.classList.remove("sidebar-enable");

    return (
      <Fragment>
        {this.renderRedirectToRoot()}

        <div className="account-pages my-5">
          <Container>
            <Row className="justify-content-center">
              <Col xl={6}>
                <Card className="">
                  <CardBody className="p-0">
                    <Row>
                      <Col md={12} className="p-5 position-relative">
                        <div className="mx-auto mb-5">
                          <a href="/">
                            <img src={logo} alt="" width="100%" />
                          </a>
                        </div>

                        <h6 className="h5 mb-0 mt-4">Bienvenue</h6>
                        <p className="text-muted mt-1 mb-4">
                          Pour vous connecter, veuillez saisir votre identifiant
                          et votre mot de passe.
                        </p>

                        {error &&  (
                          <Alert
                            color="danger"
                            isOpen={error ? true : false}
                          >
                            <div>Identifiants incorrects. Veuillez vérifier votre identifiant et votre mot de passe.</div>
                          </Alert>
                        )}

                        <AvForm
                          onValidSubmit={this.handleValidSubmit}
                          className="authentication-form"
                        >
                          <AvGroup className="">
                            <Label for="username">Identifiant</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                  <Mail className="icon-dual" />
                                </span>
                              </InputGroupAddon>
                              <AvInput
                                type="text"
                                name="username"
                                id="username"
                                placeholder="exemple@email.com"
                                required
                              />
                            </InputGroup>

                            <AvFeedback>Champ invalide</AvFeedback>
                          </AvGroup>

                          <AvGroup className="mb-3">
                            <Label for="password">Mot de passe</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                  <Lock className="icon-dual" />
                                </span>
                              </InputGroupAddon>
                              <AvInput
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Mot de passe"
                                required
                              />
                            </InputGroup>
                            <AvFeedback>Champ invalide</AvFeedback>
                          </AvGroup>

                          <FormGroup className="form-group mb-0 text-center">
                            <Button color="primary" className="btn-block">
                              Connexion
                            </Button>
                          </FormGroup>
                        </AvForm>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default Login;
