export const addOffer = (offer) => ({
  type: "ADD_OFFER",
  offer,
});

export const removeOffer = (offer) => ({
  type: "REMOVE_OFFER",
  offer,
});

export const getOffer = (id) => ({
  type: "GET_OFFER",
  id,
});

export const setOffers = (offers) => ({
  type: "SET_OFFERS",
  offers,
});

export const updateFilters = (filters) => ({
  type: "UPDATE_FILTERS",
  filters,
});

export const updateEvents = (events) => ({
  type: "UPDATE_EVENTS",
  events,
});

export const updateContacts = (contacts) => ({
  type: "UPDATE_CONTACTS",
  contacts,
});

export const updateUsers = (users) => ({
  type: "UPDATE_USERS",
  users,
});

export const updatePreferences = (preferences) => ({
  type: "UPDATE_PREFERENCES",
  preferences,
});

export const updateLastDelta = (lastdelta) => ({
  type: "UPDATE_LAST_DELTA",
  lastdelta,
});

export const updatePercentage = (percentage) => ({
  type: "UPDATE_PERCENTAGE",
  percentage,
});

export const updateProgressBarText = (progressBarText) => ({
  type: "UPDATE_PROGRESSBAR_TEXT",
  progressBarText,
});

export const setPercentage = (percentage) => ({
  type: "SET_PERCENTAGE",
  percentage,
});
export const setOffersTotal = (offersTotal) => ({
  type: "SET_OFFERS_TOTAL",
  offersTotal,
});

export const updateShowProgressBar = (showProgressBar) => ({
  type: "UPDATE_SHOW_PROGRESS_BAR",
  showProgressBar,
});

export const resetFilters = () => ({
  type: "RESET_FILTERS",
});
export const resetState = () => ({
  type: "RESET_STATE",
});
