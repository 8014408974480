import _ from "lodash";

export function getOfferType(of_type, preferences) {
  if (preferences.items_of_type) {
    const type = _.find(
      preferences.items_of_type,
      (type) => type.value === of_type
    );

    if (type === undefined) {
      return "-";
    }

    return type.text;
  }

  return "-";
}
