export const filterReference = (reference = '') => ({
    type: 'FILTER_REFERENCE',
    reference
});

export const filterType = (offerType = '') => ({
    type: 'FILTER_TYPE',
    offerType
});

export const filterBuilding = (bu_id = '') => ({
    type: 'FILTER_BUILDING',
    bu_id
});

export const filterDistrict = (district = '') => ({
    type: 'FILTER_DISTRICT',
    district
});

export const filterNbPieces = (nbPieces) => ({
    type: 'FILTER_NB_PIECES',
    nbPieces
});

export const filterBudgetMin = (budgetMin) => ({
    type: 'FILTER_BUDGET_MIN',
    budgetMin
});

export const filterBudgetMax = (budgetMax) => ({
    type: 'FILTER_BUDGET_MAX',
    budgetMax
});

export const filterSurfaceMin = (surfaceMin) => ({
    type: 'FILTER_SURFACE_MIN',
    surfaceMin
});

export const filterSurfaceMax = (surfaceMax) => ({
    type: 'FILTER_SURFACE_MAX',
    surfaceMax
});

export const filterUserName = (name) => ({
    type: 'FILTER_USER_NAME',
    name
});

export const filterContactName = (name) => ({
    type: 'FILTER_CONTACT_NAME',
    name
});

export const filterMonaco = (monaco) => ({
    type: 'FILTER_MONACO',
    monaco
});

export const filterFrance = (france) => ({
    type: 'FILTER_FRANCE',
    france
});

export const filterImmotools = (immotools) => ({
    type: 'FILTER_IMMOTOOLS',
    immotools
});

export const filterCellar = (cellar) => ({
    type: 'FILTER_CELLAR',
    cellar
});

export const startYear = (startYear) => ({
    type: 'START_YEAR',
    startYear
});

export const endYear = (endYear) => ({
    type: 'END_YEAR',
    endYear
});

export const sortBy = (sortType) => ({
    type: 'SORT_BY',
    sortType
});

const filtersReducerDefaultState = {
    type: '',
    sortBy: '',
    startYear: undefined,
    endYear: undefined
};

export const clear = () => ({
    type: 'CLEAR',
    defaultFilter: filtersReducerDefaultState
});