import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserBox from 'components/User/UserBox'
import getVisibleUsers from 'redux/selectors/getVisibleUsers';
import { filterUserName } from 'redux/actions/filters';

class Utilisateurs extends Component {
  render() {
    document.body.classList.remove('sidebar-enable');

    return (
      <div>
        <div className="row page-title align-items-center">
          <div className="col-md-9">
            <h4 className="mb-1 mt-0">Utilisateurs</h4>
          </div>
          <div className="col-md-3">
            <div className="mt-4 mt-md-0">
              <input type="text" className="form-control" placeholder="Rechercher" value={this.props.filters.name} onChange={(e) => {
                  this.props.dispatch(filterUserName(e.target.value));
              }} />
            </div>
          </div>
        </div>
        <div className="row">
          {this.props.users.map((user) => (
            <div className="col-12" key={user.co_id}>
              <UserBox user={user} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  filters: state.default.filters.users,
  users: getVisibleUsers(state.default.users, state.default.filters.users),
});

export default connect(mapStateToProps)(Utilisateurs);

  