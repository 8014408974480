import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";

class Logout extends Component { 
  /**
   * Logout
   */
  logout = () => {
    localStorage.removeItem("user")

    return <Redirect to="/login" />;
  };
  
  render() {
    return (
      <Fragment>
        {this.logout()}
      </Fragment>
    );
  }
}

export default Logout;
