import React, {Fragment} from "react";
import { Route, Redirect } from "react-router-dom";
import Topbar from "components/Layout/Topbar";
import LeftSidebar from "components/Layout/LeftSidebar";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Fragment>
    <Topbar {...rest} />
    <LeftSidebar isCondensed={false} isLight={true} {...rest} />
    <div className="content-page">
      <div className="content">
        <Route
          {...rest}
          render={(props) =>
            localStorage.getItem("user") ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }
        />
      </div>
    </div>
  </Fragment>
);
