import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import { getInitials } from "helpers/getInitials";

export default class ContactCard extends Component {
  render() {
    const { contact } = this.props;

    return (
      <Card>
        <CardBody className="profile-user-box">
          <Row>
            <Col>
              <div className="text-center mt-3">
                <div className="avatar rounded-circle bg-light m-auto">
                  <NavLink
                    to={`contact/${contact.co_id}`}
                    className="font-size-16 avatar-title text-muted font-weight-semibold"
                  >
                    {getInitials(contact.co_firstname + " " + contact.co_name)}
                  </NavLink>
                </div>
                <h5 className="mt-2 mb-0">
                  <NavLink
                    to={`contact/${contact.co_id}`}
                    className="text-dark"
                  >
                    {contact.co_firstname} {contact.co_name}
                  </NavLink>
                </h5>
                <h6 className="text-muted font-weight-normal mt-1 mb-4">
                  <NavLink
                    to={`contact/${contact.co_id}`}
                    className="text-muted"
                  >
                    {contact.co_email1}
                  </NavLink>
                </h6>

                <NavLink
                  to={`contact/${contact.co_id}`}
                  className="btn btn-primary btn-sm mr-1"
                >
                  Détail
                </NavLink>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
