import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { getInitials } from "helpers/getInitials";

export default class UserBox extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-1 text-center">
                <div className="avatar rounded-circle bg-light m-auto">
                  <NavLink
                    to={`users/edit/${user.co_id}`}
                    className="font-size-16 avatar-title text-muted font-weight-semibold"
                  >
                    {getInitials(user.co_firstname + " " + user.co_name)}
                  </NavLink>
                </div>
            </div>

            <div className="col-md-3 text-center">
              <h5 className="mt-3 mb-xs-4">
                <NavLink
                    to={`users/edit/${user.co_id}`}
                    className="text-dark"
                  >
                  {user.co_firstname} {user.co_name}
                </NavLink>
              </h5>
            </div>
            <div className="col-md-3 text-center">
              <NavLink
                  to={`users/edit/${user.co_id}`}
                  className="btn btn-primary btn-sm mt-2"
                >
                Modifier
              </NavLink>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    );
  }
}
