import React, { Component } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { getLogoBase64 } from "helpers/getLogoBase64";
import { formatPrice } from 'helpers/formatPrice';
import missing from "assets/img/missing.jpg";

export class PdfPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      base64data: null,
      images: [],
      strings: {
        fr: {
          avendre: "À VENDRE",
          alouer: "À LOUER",
          chambre: "chambre",
          chambres: "chambres",
          description: "Description",
          details: "En résumé",
          immeuble: "Immeuble",
          nombredepieces: "Nombre de pièces",
          nombredechambres: "Nombre de chambres",
          nombredeparkings: "Nombre de parkings",
          nombredecaves: "Nombre de caves",
          prix: "Prix",
          prixsurdemande: "Prix sur demande",
          surfacetotale: "Surface Totale",
          surfacelivable: "Surface Habitable",
          surfaceterrasse: "Surface Terasse",
          mentions:
            "Ce document ne fait partie d’aucune offre ou contrat. Toutes les mesures, surfaces ou distances mentionnées sont approximatives. Les descriptions, plans et photos ne sont utilisés qu’à des fins indicatives et leurs exactitudes ne sont pas garanties à 100%. Les offres présentées sont valides uniquement si les propriétés n’ont pas déjà été louées ou vendues ou bien retirées du marché et n’excluent pas d’autres changements de prix ou de conditions préalablement communiqués.",
        },
        en: {
          avendre: "FOR SALE",
          alouer: "FOR RENT",
          chambre: "bedroom",
          chambres: "bedroom",
          description: "Description",
          details: "In summary",
          immeuble: "Building",
          nombredepieces: "Number of pieces",
          nombredechambres: "Number of rooms",
          nombredeparkings: "Number of car parks",
          nombredecaves: "Number of cellars",
          prix: "Price",
          prixsurdemande: "Price upon request",
          surfacetotale: "Total surface",
          surfacelivable: "Livable surface",
          surfaceterrasse: "Terrace surface",
          mentions:
            "This document does not form part of any offer or contract. All measurements, areas or distances mentioned are approximate. Descriptions, plans and photos are used for indicative purposes only and their accuracy is not 100% guaranteed. The offers presented are valid only if the properties have not already been leased or sold or withdrawn from the market and do not exclude other changes in price or conditions previously communicated.",
        },
        it: {
          avendre: "IN VENDITA",
          alouer: "DA AFFITTARE",
          chambre: "camera da letto",
          chambres: "camere da letto",
          description: "Descrizione",
          details: "In sintesi",
          immeuble: "Edificio",
          nombredepieces: "Numero di pezzi",
          nombredechambres: "Numero di stanze",
          nombredeparkings: "Numero di parcheggi",
          nombredecaves: "Numero di cantine",
          prix: "Prezzo",
          prixsurdemande: "Prezzo su richiesta",
          surfacetotale: "Superficie totale",
          surfacelivable: "Superficie abitabile",
          surfaceterrasse: "Superficie terrazzo",
          mentions:
            "Questo documento non rappresenta un'offerta o un contratto. Tutte le misure, aree o distanze menzionate sono approssimative. Descrizioni, piante e foto sono utilizzate solo a scopo indicativo e la loro accuratezza non è garantita al 100%. Le offerte presentate sono valide solo se gli immobili non sono già stati locati o venduti o ritirati dal mercato e non escludono altre variazioni di prezzo o condizioni precedentemente comunicate.",
        },
        ru: {
          avendre: "ПРОДАЕТСЯ",
          alouer: "АРЕНДА",
          chambre: "спальня",
          chambres: "спален",
          description: "Здание",
          details: "В итоге",
          immeuble: "Описание",
          nombredepieces: "Количество штук",
          nombredechambres: "Количество комнат",
          nombredeparkings: "Количество автостоянок",
          nombredecaves: "Количество погребов",
          prix: "Цена",
          prixsurdemande: "Цена по запросу",
          surfacetotale: "Общая площадь",
          surfacelivable: "Жилая поверхность",
          surfaceterrasse: "Поверхность террасы",
          mentions:
            "Этот документ не является частью какого-либо предложения или контракта. Все указанные размеры, площади или расстояния являются приблизительными. Описания, планы и фотографии используются только в ознакомительных целях, и их точность не гарантируется на 100%. Представленные предложения действительны только в том случае, если недвижимость еще не была сдана в аренду, продана или снята с рынка, и не исключает других изменений в цене или условиях, о которых было сообщено ранее.",
        },
      },
    };
  }

  logoSrc() {
    return getLogoBase64();
  }

  render() {
    let { offer, images, lang } = this.props;

    // Register fonts
    const RobotoRegular = require("../../assets/fonts/Roboto/Roboto-Regular.ttf");
    const RobotoLight = require("../../assets/fonts/Roboto/Roboto-Light.ttf");
    const RobotoBold = require("../../assets/fonts/Roboto/Roboto-Bold.ttf");
    const RobotoBlack = require("../../assets/fonts/Roboto/Roboto-Black.ttf");
    const PrataRegular = require("../../assets/fonts/Prata/Prata-Regular.ttf");
    Font.register({
        family: "Roboto",
        fonts: [
          { src: RobotoRegular },
          { src: RobotoLight, fontWeight: 300 },
          { src: RobotoBold, fontWeight: 700 },
          { src: RobotoBlack, fontWeight: 900 },
        ],
    });
    Font.register({
      family: "Prata",
      fonts: [
        { src: PrataRegular },
      ],
    });

    // Create styles
    const styles = StyleSheet.create({
      page: {
        backgroundColor: "#F6F7FB",
        fontFamily: "Roboto",
        color: "#212529",
        paddingBottom: 80,
      },
      container: {
        padding: 20,
      },
      bgWhite: {
        backgroundColor: "#FFFFFF",
      },
      logoContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 10,
      },
      logo: {
        width: 350,
      },
      titleContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 10,
        padding: 10,
        fontSize: 14,
        backgroundColor: "#57748c",
        color: "#fff",
        fontWeight: 900,
        textTransform: "uppercase",
      },
      infosContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingVertical: 10,
        paddingHorizontal: 30,
        fontSize: 13,
        backgroundColor: "#243048",
        color: "#fff",
        fontWeight: 700,
      },
      descriptionContainer: {
        flexDirection: "row",
        padding: 30,
      },
      descriptionText: {
        flex: 2,
        marginRight: 30,
      },
      description: {
        fontSize: 11,
        lineHeight: 1.5,
        paddingBottom: 50,
      },
      detailsContainer: {
        flex: 1,
      },
      detailsBoxes: {
        flexDirection: "column",
        backgroundColor: '#fff',
        padding: 10,
      },
      detailsBox: {
        flexDirection: "row",
        justifyContent: "space-between",
        textAlign: 'left',
        fontSize: 11,
        marginBottom: 5,
      },
      detailsBoxTitle: {
        color: "#57748c",
      },
      detailsBoxValue: {
        flex: 1,
        textAlign: 'right',
      },
      h2: {
        fontFamily: "Prata",
        color: "#57748c",
        textTransform: 'uppercase',
        letterSpacing: 3,
        fontSize: 16,
        marginVertical: 10,
      },
      h3: {
        fontFamily: "Prata",
        letterSpacing: 3,
        fontSize: 13,
        marginBottom: 5,
      },
      h4: {
        fontSize: 14,
        marginBottom: 5,
      },
      imageContainer: {
        height: 220,
      },
      image: {
        width: '100%',
        height: 'auto',
      },
      sectionTitle: {
        fontSize: 12,
        padding: 10,
        fontWeight: 700,
        marginTop: 20,
      },
      line: {
        flexDirection: "row",
        justifyContent: "start",
        fontSize: 10,
        padding: 10,
      },
      odd: {
        backgroundColor: "#F9F8FD",
      },
      column: {
        flex: 1,
      },
      imagesContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
      },
      imageItem: {
        width: '33.33%',
        marginTop: 1,
      },
      footer: {
        flexDirection: "row",
        paddingVertical: 10,
        paddingHorizontal: 30,
        fontSize: 8,
        position: 'absolute',
        left:0,
        bottom:0,
        backgroundColor: 'white',
      },
      footerLeft: {
        borderLeft: 8,
        borderLeftColor: "#57748c",
        paddingLeft: 5,
        paddingVertical: 2,
        lineHeight: 1.5,
        marginRight: 30,
      },
      footerRight: {
        marginRight: 40,
        textAlign: 'justify',
        width: 340,
      },
    });

    return (
      <Document>
        <Page style={styles.page} wrap>
          <View style={styles.bgWhite} fixed>
            <View style={styles.logoContainer}>
              <Image src={this.logoSrc()} style={styles.logo} />
            </View>

            <View style={styles.titleContainer}>
              <Text>{offer['of_name_' + lang] !== '' ? offer['of_name_' + lang] : offer.of_name_en}</Text>
            </View>
          </View>
          <View style={styles.imageContainer}>
            {images.length > 0 ? (
              <Image
                src={images[0]}
                style={styles.image}
                allowDangerousPaths
              />
            ) : <Image
                src={missing}
                allowDangerousPaths
              />
            }
            </View>
            
            <View fixed style={styles.footer}>
              <View style={styles.footerLeft}>
                <Text>14 avenue de la Costa 98 000 Monaco</Text>
                <Text>Tél +377 97 97 79 29 | Fax: +377 97 97 79 28</Text>
                <Text>www.miells.com</Text>
                <Text>2020 - Miells</Text>
              </View>
              <Text style={styles.footerRight}>{this.state.strings[lang].mentions}</Text>
            </View>

          <View style={styles.infosContainer}>
            <Text>
              {offer.of_rent ? this.state.strings[lang].alouer : this.state.strings[lang].avendre} | {offer.of_nbrooms} {offer.of_nbrooms > 1 ? this.state.strings[lang].chambres : this.state.strings[lang].chambre} | {offer.of_surface} m2 | {offer.of_ref} 
            </Text>
            <Text>
              {this.state.strings[lang].prix}: {offer.price ? formatPrice(offer.price) : this.state.strings[lang].prixsurdemande}
            </Text>
          </View>

          <View style={styles.descriptionContainer}>
            <View style={styles.descriptionText}>
              <Text style={styles.h4}>{this.state.strings[lang].description}</Text>
              <Text style={styles.description}>{offer['of_text_' + lang] !== '' ? offer['of_text_' + lang] : offer.of_text_en}</Text>
            </View>
            {
              offer.of_nbpieces || offer.of_nbrooms || offer.of_surface || offer.of_surface_terrace ? 
              (
                <View style={styles.detailsContainer}>
                  <View style={styles.detailsBoxes}>
                    <Text style={styles.h4}>{this.state.strings[lang].details}</Text>
                    {offer.of_nbpieces ? (
                      <View style={styles.detailsBox}>
                        <Text style={styles.detailsBoxTitle}>{this.state.strings[lang].nombredepieces}</Text>
                        <Text style={styles.detailsBoxValue}>{offer.of_nbpieces}</Text>
                      </View>
                    ) : (null) }
                    {offer.of_nbrooms ? (
                      <View style={styles.detailsBox}>
                        <Text style={styles.detailsBoxTitle}>{this.state.strings[lang].nombredechambres}</Text>
                        <Text style={styles.detailsBoxValue}>{offer.of_nbrooms}</Text>
                      </View>
                      ) : (null)}
                    {offer.of_nbparkings ? (
                      <View style={styles.detailsBox}>
                        <Text style={styles.detailsBoxTitle}>{this.state.strings[lang].nombredeparkings}</Text>
                        <Text style={styles.detailsBoxValue}>{offer.of_nbparkings}</Text>
                      </View>
                      ) : (null)}
                    {offer.of_nbcaves ? (
                      <View style={styles.detailsBox}>
                        <Text style={styles.detailsBoxTitle}>{this.state.strings[lang].nombredecaves}</Text>
                        <Text style={styles.detailsBoxValue}>{offer.of_nbcaves}</Text>
                      </View>
                      ) : (null)}
                    {offer.of_surface ? (
                      <View style={styles.detailsBox}>
                        <Text style={styles.detailsBoxTitle}>{this.state.strings[lang].surfacetotale}</Text>
                        <Text style={styles.detailsBoxValue}>{offer.of_surface} m2</Text>
                      </View>
                    ) : (null) }
                    {offer.of_surface_livable ? (
                      <View style={styles.detailsBox}>
                        <Text style={styles.detailsBoxTitle}>{this.state.strings[lang].surfacelivable}</Text>
                        <Text style={styles.detailsBoxValue}>{offer.of_surface_livable} m2</Text>
                      </View>
                    ) : (null) }
                    {offer.of_surface_terrace ? (
                      <View style={styles.detailsBox}>
                        <Text style={styles.detailsBoxTitle}>{this.state.strings[lang].surfaceterrasse}</Text>
                        <Text style={styles.detailsBoxValue}>{offer.of_surface_terrace} m2</Text>
                      </View>
                    ) : (null) }
                  </View>
                </View>
              ) : 
              (null) 
            }
          </View>

          <View break style={styles.imageContainer}>
            {images.length > 0 ? (
              <Image
                src={images[0]}
                style={styles.image}
                allowDangerousPaths
              />
            ) : null}
          </View>
          <View style={styles.imagesContainer}>
              {images.filter((e, i) => i > 0).map((image, index) => (
                <Image
                  key={index}
                  src={image}
                  style={styles.imageItem}
                  allowDangerousPaths
                />
              ))}
          </View>
        </Page>
      </Document>
    );
  }
}
