import _ from "lodash";

export function getBuildingName(bu_id, buildings) {
  const building = _.find(buildings, (building) => building.bu_id === bu_id);

  if (building === undefined) {
    return "-";
  }

  return building.bu_name;
}
