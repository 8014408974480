import React, { Component } from 'react'
import OffersList from 'components/Offer/OffersList'

export default class Locations extends Component {
  render() {
    document.body.classList.remove('sidebar-enable');

    return (
      <OffersList title='Locations' of_rent={true} of_sell={false} />
    )
  }
}

  