// getVisibleOffers
export default (offers, { reference, offerType, bu_id, district, nbPieces, budgetMin, budgetMax, surfaceMin, surfaceMax, monaco, france, immotools, cellar }, of_rent, of_sell) => {
  return offers.filter(offer => {
    const referenceMatch = reference === '' ? true : offer.of_ref.toLowerCase().replace('-', '').includes(reference.toLowerCase().replace('-', ''))
    const ofRentSellMatch = of_rent ? offer.of_rent === of_rent : offer.of_sell === of_sell
    const typeMatch = offerType === '' ? true : offer.of_type == offerType
    const buildingMatch =
      bu_id === "" ? true : offer.bu_id && offer.bu_id.bu_id == bu_id;
    const districtMatch = district === '' ? true : offer.bu_district == district
    const nbPiecesMatch = nbPieces === '' ? true : offer.of_nbpieces == nbPieces
    const budgetMinMatch = budgetMin === '' ? true : offer.price >= budgetMin
    const budgetMaxMatch = budgetMax === '' ? true : offer.price <= budgetMax
    const surfaceMinMatch = surfaceMin === '' ? true : offer.of_surface >= surfaceMin
    const surfaceMaxMatch = surfaceMax === '' ? true : offer.of_surface <= surfaceMax
    let cellarMatch = true
    let monacoMatch = true
    let franceMatch = true

    if (monaco) {
      monacoMatch = offer.bu_id && offer.bu_id.bu_city.toLowerCase() === 'monaco' ? true : false
    }
    if (france) {
      franceMatch = offer.bu_id && offer.bu_id.bu_city.toLowerCase() !== 'monaco' ? true : false
    }
    let immotoolsMatch = offer.of_immotools == immotools ? true : false
    if (immotools) {
      immotoolsMatch = true
    }

    /*if (cellar) {
      cellarMatch = offer.of_nbcaves > 0 ? true : false
    }*/

    return ofRentSellMatch && referenceMatch && typeMatch && buildingMatch && districtMatch && nbPiecesMatch && budgetMinMatch && budgetMaxMatch && surfaceMinMatch && surfaceMaxMatch && monacoMatch && franceMatch && immotoolsMatch && cellarMatch
  }).sort((offer1, offer2) => {
    return offer1.price < offer2.price ? -1 : 1
  });
}