import React from 'react';

export function formatAddress(object, prefix = 'co_') { 
  const sep = "<br />";
  let txt = [];
  if (object[prefix+'address1']) txt.push(object[prefix+'address1']);
  if (object[prefix+'address2']) txt.push(object[prefix+'address2']);
  if (object[prefix+'address3']) txt.push(object[prefix+'address3']);
  if (object[prefix + 'zip'] && object[prefix + 'city']) txt.push(object[prefix + 'zip'] + " " + object[prefix + 'city']);
  if (object[prefix + 'country']) txt.push(object[prefix + 'country']);
  
  return (<a href={'https://www.google.fr/maps?q=' + encodeURI(txt.join(', '))} target="_blank "><div dangerouslySetInnerHTML={{__html: txt.join(sep)}} /></a>)
}