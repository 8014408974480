import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getInitials } from "helpers/getInitials";
import { mailTo } from "helpers/mailTo";
import { telTo } from "helpers/telTo";
import _ from "lodash";

class UserEdit extends Component {
  render() {
    document.body.classList.remove("sidebar-enable");
    const { user } = this.props;

    return (
      <div>
        {user ? (
          <div>
            <div className="row page-title align-items-center">
              <div className="col-md-3 col-xl-6">
                <h4 className="mb-1 mt-0">
                  Modifier l'utilisateur {user.co_firstname} {user.co_name}
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <div className="text-center mt-3">
                      <div className="avatar rounded-circle bg-light m-auto">
                        <span to={`user/${user.co_id}`} className="font-size-16 avatar-title text-muted font-weight-semibold">
                          {getInitials(
                            user.co_firstname + " " + user.co_name
                          )}
                          </span>
                      </div>
                      <h5 className="mt-2 mb-0">
                        {user.co_firstname} {user.co_name}
                      </h5>
                      <h6 className="text-muted font-weight-normal mt-1 mb-4">
                        {user.co_email1}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mb-3 font-size-15">Coordonnées</h4>
                    <div className="table-responsive">
                      <table className="table table-borderless mb-0 text-muted">
                        <tbody>
                          <tr>
                            <th scope="row">Email 1</th>
                            <td>{ mailTo(user.co_email1) }</td>
                          </tr>
                          <tr>
                            <th scope="row">Email 2</th>
                            <td>{ mailTo(user.co_email2) }</td>
                          </tr>
                          <tr>
                            <th scope="row">Email 3</th>
                            <td>{ mailTo(user.co_email3) }</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile 1</th>
                            <td>{ telTo(user.co_mobile1) }</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile 2</th>
                            <td>{ telTo(user.co_mobile2) }</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile 3</th>
                            <td>{ telTo(user.co_mobile3) }</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: _.find(
    state.default.users,
    (user) => user.co_id === ownProps.match.params.id
  ),
});

export default withRouter(connect(mapStateToProps)(UserEdit));
