import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { getInitials } from "helpers/getInitials";
import { getUser } from "helpers/getUser";


/**
 * User Widget
 */
const UserProfile = (props) => {
    const user = getUser()

    return <React.Fragment>
        <div className="media user-profile mt-2 mb-2">
            {
                user ? 
                    (
                    <div className="d-flex justify-content-center">
                        <div className="avatar rounded-circle bg-light align-self-center mr-2">
                            <span className="font-size-16 avatar-title text-muted font-weight-semibold">{getInitials(user.data.co_firstname + " " + user.data.co_name)}</span>
                        </div>
                        <div className="media-body align-self-center mr-2">
                            { user.data.co_firstname + " " + user.data.co_name }
                            <div className={ navigator.onLine ? 'text-success' : 'text-danger' }>{ navigator.onLine ? 'En ligne' : 'Hors ligne' }</div>
                        </div>
                        <UncontrolledDropdown className="align-self-center profile-dropdown-menu">
                            <DropdownToggle
                                data-toggle="dropdown"
                                tag="button"
                                className="btn btn-link p-0 dropdown-toggle mr-0">
                                <FeatherIcon.ChevronDown />
                            </DropdownToggle>
                            <DropdownMenu right className="topbar-dropdown-menu profile-dropdown-items">
                                <DropdownItem divider />
                                <Link to="/logout" className="dropdown-item notify-item">
                                    <FeatherIcon.LogOut className="icon-dual icon-xs mr-2" />
                                    <span>Déconnexion</span>
                                </Link>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
                :
                (null)
            }
        </div>
    </React.Fragment>
}


/**
 * Sidenav
 */
const SideNav = () => {
    return <div className="sidebar-content">
        <div id="sidebar-menu">
            <ul className="metismenu" id="menu-bar">
                <li className="menu-title">Offres</li>
                <li>
                    <Link to="/ventes" className="dropdown-item notify-item">
                        <FeatherIcon.Box className="icon-dual icon-xs mr-2" />
                        <span>Ventes</span>
                    </Link>
                </li>
                <li>
                    <Link to="/locations" className="dropdown-item notify-item">
                        <FeatherIcon.Briefcase className="icon-dual icon-xs mr-2" />
                        <span>Locations</span>
                    </Link>
                </li>
                <li className="menu-title">Événements</li>
                <li>
                    <Link to="/calendrier" className="dropdown-item notify-item">
                        <FeatherIcon.Calendar className="icon-dual icon-xs mr-2" />
                        <span>Calendrier</span>
                    </Link>
                </li>
                <li className="menu-title">Personnes</li>
                <li>
                    <Link to="/contacts" className="dropdown-item notify-item">
                        <FeatherIcon.AtSign className="icon-dual icon-xs mr-2" />
                        <span>Contacts</span>
                    </Link>
                </li>
                <li className="menu-title">Données</li>
                <li>
                    <Link to="/delete-all" className="dropdown-item notify-item">
                        <FeatherIcon.Trash2 className="icon-dual icon-xs mr-2" />
                        <span>Tout supprimer</span>
                    </Link>
                </li>
            </ul>
            <p>Version 1.4</p>
        </div>
    </div>
}

class LeftSidebar extends Component {
    menuNodeRef;

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleOtherClick = this.handleOtherClick.bind(this);
    }

    /**
     * Bind event
     */
    componentDidMount = () => {
        document.addEventListener('mousedown', this.handleOtherClick, false);
    };

    /**
     * Bind event
     */
    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleOtherClick, false);
    };

    /**
     * Handle the click anywhere in doc
     */
    handleOtherClick = (e) => {
        if (this.menuNodeRef.contains(e.target)) return;
        // else hide the menubar
        if (document.body) {
            document.body.classList.remove('sidebar-enable');
        }
    };

    /**
     * Handle click
     * @param {*} e
     * @param {*} item
     */
    handleClick(e) {
        document.body.classList.remove('sidebar-enable');
    }

    render() {
        const isCondensed = this.props.isCondensed || false
        const { user } = this.props;

        return (
            <React.Fragment>
                <div className='left-side-menu' ref={node => this.menuNodeRef = node}>
                    <UserProfile user={user} />
                    {!isCondensed && <PerfectScrollbar><SideNav /></PerfectScrollbar>}
                    {isCondensed && <SideNav />}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.default.user,
});

export default connect(mapStateToProps)(LeftSidebar);
