import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from "redux/actions.js";
import ProgressBar from "components/Layout/ProgressBar";
import axios from "axios";
import { getUser } from "helpers/getUser";
import { groupOffers } from "helpers/groupOffers";
import { getOfferImages } from "helpers/getOfferImages";
import moment from "moment";
import { getImageUrl } from 'helpers/getImageUrl';

class Synchronizer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			percentage: 0,
			offers: [],
			showProgressBar: true,
			progressBarText: "Synchronisation des données...",
			user: getUser(),
			API_URL: 'https://immonaco-backend.miells.com',
			secretHeaders: {
				"x-auth-apikey": "a59c2edc-8cb3-4261-a5cb-f1738979e8fe",
				"x-auth-apisecret": "592716ea-1b4d-4a2e-9dcc-b8bddd196b1b",
			},
		};
	}

	componentDidMount() {
		if (navigator.onLine) {
			this.autosign();
			this.setPreferences();
			this.setEvents();
			this.setContacts();
			this.setOffers();
		} else {
			this.setState({
				percentage: 0,
				progressBarText: "Synchronisation réseau impossible",
				showProgressBar: true,
			});
		}
	}

	multiQueue = (max) => {
		max = isNaN(max) || max < 1 ? 1 : max;
		const q = new Array(max).fill(0).map(() => Promise.resolve());
		let index = 0;
		const add = (cb, ...args) => {
			index = (index + 1) % max;
			return (q[index] = q[index].then(() => cb(...args)));
		};
		return add;
	};

	// Autosign
	async autosign() {
		const { user, API_URL } = this.state;

		const tokenParams = user
			? new URLSearchParams({
				accesstoken: user.accesstoken,
			}).toString()
			: {};

		await axios
			.post(`${API_URL}/api-web/1.0/autosignin`, tokenParams, {
				headers: { 'x-auth-accesstoken': user.accesstoken },
			})
			.then((response) => {
				if (response.data.data !== null) {
					const user = JSON.stringify(response.data)
					localStorage.setItem("user", user);
					this.setState({ user: user })
				} else {
					localStorage.removeItem("user");
				}
			});
	}

	// Get user contacts
	async setContacts() {
		const { user, API_URL } = this.state;

		await axios
			.get(`${API_URL}/api/1.0/contacts`, { headers: { 'x-auth-accesstoken': user.accesstoken } })
			.then((response) => {
				const contacts = response.data.data.map(contact => {
					return {
						co_id: contact.co_id,
						co_firstname: contact.co_firstname,
						co_name: contact.co_name,
						co_email1: contact.co_email1,
						co_email2: contact.co_email2,
						co_email3: contact.co_email3,
						co_tel1: contact.co_tel1,
						co_tel2: contact.co_tel2,
						co_tel3: contact.co_tel3,
						co_mobile1: contact.co_mobile1,
						co_mobile2: contact.co_mobile2,
						co_mobile3: contact.co_mobile3,
						co_address1: contact.co_address1,
						co_address2: contact.co_address2,
						co_address3: contact.co_address3,
						zip: contact.zip,
						city: contact.city,
						country: contact.country,
					};
				})
				this.props.updateContacts(contacts);
			});
	}

	// Get user events
	async setEvents() {
		const { user, API_URL } = this.state;

		await axios
			.get(`${API_URL}/api/1.0/events`, {
				headers: { 'x-auth-accesstoken': user.accesstoken },
				params: {
					users: [user.data.co_id],
				},
			})
			.then((response) => {
				let events = [];

				if (response) {
					let results = response.data.data;

					results.map((result) => {
						let title =
							moment(result.ve_dtstart).format("HH[H]mm") + " " + result.ve_summary;
						if (result.co_id_contact) {
							title += " | " + result.co_id_contact.co_firstname;
						}
						if (result.visits.length > 0) {
							result.visits.map((visit) => {
								if (visit.offers.length > 0) {
									visit.offers.map((offer) => {
										title += " | " + offer.of_ref;
										return offer;
									});
								}

								return visit;
							});
						}

						events.push({
							id: result.ve_id,
							title: title,
							description: title,
							start: result.ve_dtstart,
							end: result.ve_dtend,
							url: "mailto:",
						});

						return result;
					});
				}

				this.props.updateEvents(events);
			});
	}

	async setPreferences() {
		const { API_URL, secretHeaders } = this.state;

		// Get preferences
		if (this.props.default.preferences.length === 0) {
			await axios
				.get(`${API_URL}/api/1.0/preferences`, { headers: secretHeaders })
				.then((response) => {
					const buildings = response.data.data.buildings.map(building => ({ bu_id: building.bu_id, bu_name: building.bu_name }))
					const items_bu_district = response.data.data.items.items_bu_district.map(district => ({ value: district.value, text: district.text }))
					const items_of_type = response.data.data.items.items_of_type.map(type => ({ value: type.value, text: type.text }))
					const items_of_disponibility = response.data.data.items.items_of_disponibility.map(disponibility => ({ value: disponibility.value, text: disponibility.text }))

					this.props.updatePreferences({
						items_of_disponibility: items_of_disponibility,
						buildings: buildings,
						items_bu_district: items_bu_district,
						items_of_type: items_of_type,
					});
				});
		}
	}

	// Offers
	async setOffers() {
		const { user, API_URL } = this.state;

		this.props.setOffers([]);

		await axios
			.get(`${API_URL}/api/1.0/offers?immotools=false&of_status=1`, { headers: { 'x-auth-accesstoken': user.accesstoken } })
			.then((response) => {
				const results = response.data.data;
				const toAdd = groupOffers(results, this.props).toAdd;

				if (toAdd.length > 0) {
					var sequence = Promise.resolve()

					toAdd.map((offer, resultIndex) => {
						if (offer.nbImages > 0) {
							sequence = sequence.then(() => {
								return getOfferImages(offer)
							}).then((response, that) => {
								let imagesLoaded = 0;
								let images = response.data.data;
								
								images.slice(0, 4).map((image, imageIndex) => {
									if (image.base64) {
										offer.images.push({ base64: image.base64 });
									}
									imagesLoaded++;
									if (imagesLoaded === images.slice(0, 4).length) {
										this.state.offers.push(offer);
										this.updatePercentage(toAdd, resultIndex)
									}
									return image;
								});
							}).catch(function (err) {
								console.log(err)
							})
						} else {
							sequence = sequence.then(() => {
								return new Promise(function (resolve, reject) {
									resolve();
								}).then((response, that) => {
									this.state.offers.push(offer);
									this.updatePercentage(toAdd, resultIndex)
								})
							})
						}

						//this.state.offers.push(offer);
						//this.updatePercentage(toAdd, resultIndex)

						return offer;
					})
				} else {
					this.setState({
						percentage: 100,
						progressBarText: "Aucune mise à jour"
					})
					setTimeout(() => {
						this.setState({
							showProgressBar: false
						})
					}, 1000);
				}
			})
	}

	getImages() {
		let ids = [];

		this.state.offers.map((offer) => {
			if (offer.images.length > 0) {
				offer.images.map((image) => {
				if (image.fi_id) ids.push(image.fi_id);
				});
			}
		});

		return ids;
	}

	async cacheImages() {
		this.setState({
            progressBarText: "Chargement des images...",
		});
		
		const images = this.getImages()
		const promises = await images.map((fi_id) => {
			return new Promise(function (resolve, reject) {
				const img = new Image();

				img.src = getImageUrl(fi_id)
				img.onload = resolve();
				img.onerror = reject();
			});
		});

		await Promise.all(promises);
	}

	updatePercentage(toAdd, index) {
		this.setState({ percentage: (index / toAdd.length) * 100 });

		if (index === toAdd.length - 1) {
			this.props.setOffers(this.state.offers);

			setTimeout(() => {
				this.setState({
					showProgressBar: false,
				});
			}, 300);
		}
	}

	render() {
		const { percentage, showProgressBar, progressBarText } = this.state;

		return (
      <div>
        <ProgressBar
          percentage={percentage}
          showProgressBar={showProgressBar}
          progressBarText={progressBarText}
        />
      </div>
    );
	}
}

let mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps, actionCreators)(Synchronizer);
