import React, { Component } from 'react'
import { Link } from "react-router-dom"

class ProgressBar extends Component {
	render() {
		const { percentage, showProgressBar, progressBarText } = this.props;

		return (
      <div className="progress-bar card">
        <div className="card-body">
          {showProgressBar ? (
            <div>
              <div className="bar">
                <div
                  style={{
                    backgroundColor: "#fff",
                    width: percentage + "%",
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: "#969696",
                    width: 100 - percentage + "%",
                  }}
                ></div>
              </div>
              <div className="legend">
                <div>
                  <span className="label">{progressBarText}</span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p>Synchronisation terminée</p>
              <p>
                <Link to="/" className="btn btn-white">
                  Retour à l'accueil
                </Link>
              </p>
            </div>
          )}
        </div>
      </div>
    );
	}
}
export default ProgressBar;
