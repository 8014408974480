import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { PdfPage } from "pages/Offer/PdfPage";
import { getImageAsBlob } from "helpers/getImageAsBlob";
import _ from "lodash";

class PdfPageWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      base64data: null,
      images: [],
      strings: {
        'fr': {
          'subject': "MIELLS CHRISTIE'S - Sélectionné pour vous par notre agent immobilier",
          'bodyFirst': "Vous nous avez contacté afin d'obtenir plus d'information concernant ce(s) bien(s)",
          'bodySecond': "Vous pouvez consulter la fiche et toutes les photos dans le document PDF ci-joint.%0D%0A%0D%0ACordialement,",
        },
        'en': {
          'subject': "MIELLS CHRISTIE'S - Selected for you by our real estate agent",
          'bodyFirst': "You have reached us in order to have more details on",
          'bodySecond': "Please find attached an information sheet on the property.%0D%0A%0D%0ABest regards,",
        },
        'it': {
          'subject': "MIELLS CHRISTIE'S - Selezionato per te dal nostro agente immobiliare",
          'bodyFirst': "Ci ha contattato per ottenere maggiori informazioni riguardo la (le) seguente (i) proprietà",
          'bodySecond': "Le é possibile consultare la scheda e le foto nel documento PDF qui allegato.%0D%0A%0D%0ACordiali saluti,",
        },
        'ru': {
          'subject': "MIELLS CHRISTIE'S - Выбран для вас нашим агентом по недвижимости",
          'bodyFirst': "Вы обратились для получения дополнительной информации об этом объекте",
          'bodySecond': "Во вложенном файле Вы найдете фотографии и описание в формате PDF.%0D%0A%0D%0AС Уважением,",
        },
      }
    };
  }

  componentDidMount() {
    if (this.props.offer !== undefined) {
      this.showImages();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offer !== this.props.offer) {
      this.showImages();
    }
  }

  showImages() {
    const { offer } = this.props;
    if (offer && offer.images.length > 0) {
      let images = [];
      var imagesLoaded = 0;

      offer.images.map((image) => {
          const fileReaderInstance = new FileReader();

         let buffer = getImageAsBlob(image.base64);

         if (buffer instanceof Blob) {
           fileReaderInstance.readAsDataURL(buffer);
           fileReaderInstance.onload = () => {
             images.push(fileReaderInstance.result);
             imagesLoaded++;
             if (imagesLoaded === offer.images.length)
               this.allImagesLoaded(images);
           };
         }

         return image;
      });

      /*offer.blobImages.map((image) => {
        const fileReaderInstance = new FileReader();
        let buffer = getImageAsBlob(image);

        if (buffer instanceof Blob) {
          fileReaderInstance.readAsDataURL(buffer);
          fileReaderInstance.onload = () => {
            images.push(fileReaderInstance.result);
            imagesLoaded++;
            if (imagesLoaded === offer.blobImages.length)
              this.allImagesLoaded(images);
          };
        }

        return image;
      });*/
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  allImagesLoaded(images) {
    this.setState({
      images: images,
      loading: false,
    });
  }

  render() {
    let { offer } = this.props;
    let { lang } = this.props.match.params;
    let { images, strings } = this.state;

    return (
      <Fragment>
        {!this.state.loading && offer ? (
          <Fragment>
            <button className="btn btn-danger btn-block mt-5"
              onClick={async () => {
                let fileName = offer.of_ref + '-complete-' + lang + '.pdf';
                const doc = <PdfPage offer={offer} images={images} lang={lang} {...this.props} />;
                const asPdf = pdf(doc);
                let blob = await asPdf.toBlob();
                blob = blob.slice(0, blob.size, "application/pdf")
                saveAs(blob, fileName);

                /*
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                a.click();*/

              }}
            >
              Télécharger le PDF
            </button>
            
            <a target="_blank" rel="noopener noreferrer" className="btn btn-dark btn-block mt-5"
              href={ 'mailto:?subject=' + strings[lang].subject + '&body=' + strings[lang].bodyFirst + ' : ' + offer['of_name_' + lang] + '.%0D%0A%0D%0A' + strings[lang].bodySecond}
            >
              Envoyer par e-mail
            </a>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  offer: _.find(
    state.default.offers,
    (offer) => offer.id === ownProps.match.params.id
  ),
  users: state.default.users,
  preferences: state.default.preferences,
});

export default withRouter(connect(mapStateToProps)(PdfPageWrapper));
