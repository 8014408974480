import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router";
import { getInitials } from "helpers/getInitials";
import { mailTo } from "helpers/mailTo";
import { telTo } from "helpers/telTo";
import { formatAddress } from "helpers/formatAddress";
import * as FeatherIcon from "react-feather";

class ContactDetail extends Component {
  render() {
    document.body.classList.remove("sidebar-enable");
    const { contact } = this.props;

    return (
      <div>
        {contact ? (
          <div>
            <div className="row page-title">
              <h4 className="mb-1 mt-0 ml-3">
                <FeatherIcon.ChevronLeft className="icon-xs" />
                <button
                  onClick={this.props.history.goBack}
                  className="btn btn-link btn-back"
                >
                  Retour
                </button>
              </h4>
            </div>
            <div className="row page-title align-items-center">
              <div className="col-md-3 col-xl-6">
                <h4 className="mb-1 mt-0">
                  {contact.co_firstname} {contact.co_name}
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <div className="text-center mt-3">
                      <div className="avatar rounded-circle bg-light m-auto">
                        <span
                          to={`contact/${contact.co_id}`}
                          className="font-size-16 avatar-title text-muted font-weight-semibold"
                        >
                          {getInitials(
                            contact.co_firstname + " " + contact.co_name
                          )}
                        </span>
                      </div>
                      <h5 className="mt-2 mb-0">
                        {contact.co_firstname} {contact.co_name}
                      </h5>
                      <h6 className="text-muted font-weight-normal mt-1 mb-4">
                        {contact.co_email1}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mb-3 font-size-15">Coordonnées</h4>
                    <div className="table-responsive">
                      <table className="table table-borderless mb-0 text-muted">
                        <tbody>
                          {contact.co_email1 ? (
                            <tr>
                              <th scope="row">Email 1</th>
                              <td>{mailTo(contact.co_email1)}</td>
                            </tr>
                          ) : null}
                          {contact.co_email2 ? (
                            <tr>
                              <th scope="row">Email 2</th>
                              <td>{mailTo(contact.co_email2)}</td>
                            </tr>
                          ) : null}
                          {contact.co_email3 ? (
                            <tr>
                              <th scope="row">Email 3</th>
                              <td>{mailTo(contact.co_email3)}</td>
                            </tr>
                          ) : null}
                          {contact.co_tel1 ? (
                            <tr>
                              <th scope="row">Téléphone 1</th>
                              <td>{telTo(contact.co_tel1)}</td>
                            </tr>
                          ) : null}
                          {contact.co_tel2 ? (
                            <tr>
                              <th scope="row">Téléphone 2</th>
                              <td>{telTo(contact.co_tel2)}</td>
                            </tr>
                          ) : null}
                          {contact.co_tel3 ? (
                            <tr>
                              <th scope="row">Téléphone 3</th>
                              <td>{telTo(contact.co_tel3)}</td>
                            </tr>
                          ) : null}
                          {contact.co_mobile1 ? (
                            <tr>
                              <th scope="row">Mobile 1</th>
                              <td>{telTo(contact.co_mobile1)}</td>
                            </tr>
                          ) : null}
                          {contact.co_mobile2 ? (
                            <tr>
                              <th scope="row">Mobile 2</th>
                              <td>{telTo(contact.co_mobile2)}</td>
                            </tr>
                          ) : null}
                          {contact.co_mobile3 ? (
                            <tr>
                              <th scope="row">Mobile 3</th>
                              <td>{telTo(contact.co_mobile3)}</td>
                            </tr>
                          ) : null}
                          <tr>
                            <th scope="row">Adresse</th>
                            <td>{formatAddress(contact, "co_")}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  contact: _.find(
    state.default.contacts,
    (contact) => contact.co_id === ownProps.match.params.id
  ),
});

export default withRouter(connect(mapStateToProps)(ContactDetail));
