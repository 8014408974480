import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { resetFilters } from 'redux/actions'
import {
  filterReference,
  filterType,
  filterNbPieces,
  filterBudgetMin,
  filterBudgetMax,
  filterSurfaceMin,
  filterSurfaceMax,
  filterBuilding,
  filterDistrict,
  filterMonaco,
  filterFrance,
  filterImmotools,
} from "redux/actions/filters";

class OffersFilters extends Component {
  state = {
    buildings: [],
    districts: [],
    selectedBuilding: { value: '', label: 'Immeuble' },
    selectedDistrict: { value: '', label: 'Quartier' },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.preferences.buildings !== this.props.preferences.buildings) {
      this.setBuildings(this.props.preferences.buildings, this.props.filters.offers.bu_id)
    }
    if (prevProps.preferences.items_bu_district !== this.props.preferences.items_bu_district) {
      this.setDistricts(this.props.preferences.items_bu_district, this.props.filters.offers.district)
    }
  }

  componentDidMount() {
    if (this.props.preferences) {
      let { buildings } = this.props.preferences;
      let { items_bu_district } = this.props.preferences;
      if (buildings) {
        this.setBuildings(buildings, this.props.filters.offers.bu_id);
      }
      if (items_bu_district) {
        this.setDistricts(
          items_bu_district,
          this.props.filters.offers.district
        );
      }
    }
  }

  setBuildings(items, bu_id) {
    let buildings = [{ value: '', label: 'Immeuble' }]

    items.map((item) => {
      buildings.push({ value: item.bu_id, label: item.bu_name })

      if (item.bu_id === bu_id) {
        this.setState({ selectedBuilding: { value: item.bu_id, label: item.bu_name } })
      }

      return item;
    })
    this.setState({
      buildings: buildings,
    });
  }

  setDistricts(items, district) {
    let districts = [{ value: '', label: 'Quartier' }]

    items.map((item) => {
      if (item.text !== 'Aucun') {
        districts.push({ value: item.value, label: item.text })

        if (item.value === district) {
          this.setState({ selectedDistrict: { value: item.value, label: item.text } })
        }
      }

      return item;
    })
    this.setState({
      districts: districts,
    });
  }

  handleBuildingChange = selectedBuilding => {
    this.setState(
      { selectedBuilding }
    );
    this.props.dispatch(filterBuilding(selectedBuilding.value));
  };

  handleDistrictChange = selectedDistrict => {
    this.setState(
      { selectedDistrict }
    );
    this.props.dispatch(filterDistrict(selectedDistrict.label));
  };

  reset = () => {
    this.props.dispatch(resetFilters()); 
    this.setState({
      selectedBuilding: { value: '', label: 'Immeuble' },
      selectedDistrict: { value: '', label: 'Quartier' },
    })
  }

  render() {
    const props = this.props
    const { count } = this.props
    const { buildings, districts, selectedBuilding, selectedDistrict } = this.state

    return (
      <div>
        <div className="row">
          <div className="col-xl-2 col-12 mb-2">
            <input
              type="text"
              className="form-control"
              placeholder="Référence"
              value={props.filters.offers.reference}
              onChange={(e) => {
                props.dispatch(filterReference(e.target.value));
              }}
            />
          </div>
          <div className="col-xl-2 col-12 mb-2">
            <select
              className="custom-select w-100"
              value={props.filters.offers.offerType}
              onChange={(e) => {
                props.dispatch(filterType(e.target.value));
              }}
            >
              <option value="">Type</option>
              {props.preferences.items_of_type ? props.preferences.items_of_type.map((type) => (
                    <option value={type.value} key={type.value}>
                      {type.text}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="col-xl-2 col-12 mb-2">
            <Select
              className="react-select w-100 bg-white"
              classNamePrefix="react-select"
              options={buildings}
              value={selectedBuilding}
              onChange={this.handleBuildingChange}
            ></Select>
          </div>
          <div className="col-xl-2 col-12 mb-2">
            <Select
              className="react-select w-100 bg-white"
              classNamePrefix="react-select"
              options={districts}
              value={selectedDistrict}
              onChange={this.handleDistrictChange}
            ></Select>
          </div>
          <div className="col-xl-2 col-12 mb-2">
            <select
              className="custom-select w-100"
              value={props.filters.offers.nbPieces}
              onChange={(e) => {
                props.dispatch(filterNbPieces(e.target.value));
              }}
            >
              <option value="">Nombre de pièces</option>
              <option value="1">Studio</option>
              <option value="2">2P</option>
              <option value="3">3P</option>
              <option value="4">4P</option>
              <option value="5">5P+</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-2 col-6 mb-2">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Budget min"
                value={props.filters.offers.budgetMin}
                onChange={(e) => {
                  props.dispatch(filterBudgetMin(e.target.value));
                }}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <div className="mx-1">€</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-6 mb-2">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Budget max"
                value={props.filters.offers.budgetMax}
                onChange={(e) => {
                  props.dispatch(filterBudgetMax(e.target.value));
                }}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <div className="mx-1">€</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-6 mb-2">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Surface min"
                value={props.filters.offers.surfaceMin}
                onChange={(e) => {
                  props.dispatch(filterSurfaceMin(e.target.value));
                }}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <small>
                    m<sup>2</sup>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-6 mb-2">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="surfaceMax"
                placeholder="Surface max"
                value={props.filters.offers.surfaceMax}
                onChange={(e) => {
                  props.dispatch(filterSurfaceMax(e.target.value));
                }}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <small>
                    m<sup>2</sup>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-6 mb-2">
            <button onClick={() => this.reset()} className="btn btn-dark">
              Reset
            </button>
          </div>
        </div>
        <div className="row page-title align-items-center">
          <div className="col-sm-9 col-3">
            {count > 0 ? <h5>{count} offres</h5> : ""}
          </div>
          <div className="col-sm-3 col-9 d-flex justify-content-end">
            <div className="custom-control custom-switch ml-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="monaco"
                checked={props.filters.offers.monaco}
                onChange={(e) => {
                  props.dispatch(filterMonaco(e.target.checked));
                }}
              />
              <label className="custom-control-label" htmlFor="monaco">
                Monaco
              </label>
            </div>
            <div className="custom-control custom-switch ml-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="france"
                checked={props.filters.offers.france}
                onChange={(e) => {
                  props.dispatch(filterFrance(e.target.checked));
                }}
              />
              <label className="custom-control-label" htmlFor="france">
                France
              </label>
            </div>
            {/*<div className="custom-control custom-switch ml-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="immotools"
                checked={props.filters.offers.immotools}
                onChange={(e) => {
                  props.dispatch(filterImmotools(e.target.checked));
                }}
              />
              <label className="custom-control-label" htmlFor="immotools">
                Immotools
              </label>
              </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.default.filters,
    preferences: state.default.preferences,
  };
};

export default connect(mapStateToProps)(OffersFilters);
