import React, { Component } from "react";
import OffersList from 'components/Offer/OffersList';

export default class Ventes extends Component {
  render() {
    document.body.classList.remove('sidebar-enable');

    return (  
      <OffersList title='Ventes' of_sell={true} of_rent={false} />
    );
  }
}