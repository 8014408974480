import React, { Component, Fragment } from "react"
import { connect } from 'react-redux'
import { resetState } from 'redux/actions'
import {
  Alert,
} from "reactstrap"

class DeleteAll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMessage: false,
      showLogout: false,
    };
  }

  deleteAll = () => {
    this.props.resetState(); 
    this.setState({
      showMessage: !this.state.showMessage
    })
  }

  render() {
    document.body.classList.remove("sidebar-enable");
    let { showMessage } = this.state;
    
    return (
      <Fragment>
        {showMessage &&  (
          <Alert
            color="success"
            isOpen={showMessage ? true : false}
          >
            <div>Les données ont bien été supprimées.</div>
          </Alert>
        )}
        <button className="btn btn-danger btn-block mt-5"
          onClick={() => this.deleteAll()}>
          Supprimer toutes les données
        </button>
      </Fragment>
    );
  }
}


let mapStateToProps = (state) => {
  return state
} 

const mapDispatchToProps = dispatch => ({
  resetState: () => dispatch(resetState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAll)
