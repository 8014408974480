import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

import calImg from 'assets/images/cal.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class CalendarApp extends Component {
    handleEventClick = ({ info }) => {
        info.jsEvent.preventDefault();
    };
    

    render() {
        document.body.classList.remove('sidebar-enable');

        const { events } = this.props;

        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col className="col-12">
                    
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="align-items-center">
                                    <Col xl={2} lg={3}>
                                        <img src={calImg} className="mr-4 align-self-center img-fluid" alt="cal" />
                                    </Col>
                                    <Col xl={10} lg={9}>
                                        <div className="mt-4 mt-lg-0">
                                            <h5 className="mt-0 mb-1 font-weight-bold">Bienvenue dans votre calendrier</h5>
                                            <p className="text-muted mb-2">Ce calendrier présente les évènement synchronisés depuis le serveur.
                                            <br />Cliquez sur un évènement pour télécharger le fichier .ics et ouvrez-le pour ajouter l'évènement à Outlook.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                {/* fullcalendar control */}
                                <FullCalendar
                                    locale="fr"
                                    defaultView="listMonth"
                                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                                    slotDuration='00:15:00'
                                    minTime='08:00:00' maxTime='19:00:00' themeSystem='bootstrap'
                                    handleWindowResize={true}
                                    bootstrapFontAwesome={false}
                                    buttonText={{ today: "Aujourd'hui", month: 'Mois', week: 'Semaine', day: 'Jour', list: 'Liste', prev: 'Précédent', next: 'Suivant' }}
                                    header={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                                    }}
                                    droppable={false}
                                    editable={false}
                                    eventLimit={false} // allow "more" link when too many events
                                    selectable={false}
                                    events={events}
                                    id="calendar"
                                    eventClick={this.handleEventClick}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
};

const mapStateToProps = (state) => ({
    events: state.default.events,
  });
  
export default withRouter(connect(mapStateToProps)(CalendarApp));
  