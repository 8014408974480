import React, { Component } from "react";
import { connect } from 'react-redux'
import * as actionCreators from 'redux/actions.js';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Menu, X } from 'react-feather';

import logo from 'assets/img/new_logo.png';

class TopBar extends Component {
  /**
   * Opens the left menu - mobile
   */
  openLeftMenu = () => {
    if (document.body) {
      if (document.body.classList.contains("sidebar-enable")) {
        document.body.classList.remove("sidebar-enable");
      } else {
        if (document.body.classList.contains("left-side-menu-condensed"))
          document.body.classList.remove("left-side-menu-condensed");
        document.body.classList.add("sidebar-enable");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="navbar navbar-expand flex-column flex-md-row navbar-custom">
          <Container fluid>
            {/* menu*/}
            <ul className="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
              <li>
                <button
                  className="button-menu-mobile open-left disable-btn"
                  onClick={this.openLeftMenu}
                >
                  <Menu className="menu-icon" />
                  <X className="close-icon" />
                </button>
              </li>

              <Link to="/" className="navbar-brand mr-0 ml-md-2 logo">
                <span className="logo-lg">
                  <img src={logo} alt="" height="67" />
                </span>
                <span className="logo-sm">
                  <img src={logo} alt="" height="36" />
                </span>
              </Link>
            </ul>

            <Link to="/" className="d-block d-md-none">
              <span className="logo-sm m-auto">
                <img src={logo} alt="" className="logo-mobile" />
              </span>
            </Link>

            <Link to="/synchronize" className="btn btn-danger mr-4">
              <i className="uil-sync mr-1"></i>{" "}
              <span className="d-none d-sm-inline">Synchroniser</span>
            </Link>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => {
  return state
} 

export default connect (mapStateToProps, actionCreators)(TopBar);