import React, { Component } from "react";
import OfferCard from "components/Offer/OfferCard";
import OffersFilters from "components/Offer/OffersFilters";
import { connect } from "react-redux";
import getVisibleOffers from 'redux/selectors/getVisibleOffers'

class OffersList extends Component {
  render() {
    const { title, offers, reduxStorageLoaded } = this.props;

    return (
      <div>
        <div className="row page-title">
          <div className="col-sm-12">
            <h4 className="mb-1 mt-0">{title}</h4>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-sm-12">
            <OffersFilters count={offers.length} />
          </div>
        </div>

        {!reduxStorageLoaded ? (
          <div className="row text-center mt-5">
            <div className="col-12">
              <div className="spinner-border text-primary">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : null}
        
        <div className="row">
          {offers.map((offer) => (
            <div className="col-xl-3 col-lg-6" key={offer.id}>
              <OfferCard offer={offer} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  offers: getVisibleOffers(state.default.offers, state.default.filters.offers, ownProps.of_rent, ownProps.of_sell),
  reduxStorageLoaded: state.default.reduxStorageLoaded,
})

export default connect(mapStateToProps)(OffersList);
