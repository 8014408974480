import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute } from 'components/Layout/PrivateRoute';
import Ventes from "pages/Ventes";
import Offer from "pages/Offer";
import Event from "pages/Calendrier/Event";
import ContactDetail from "pages/Contacts/detail";
import UserEdit from "pages/Utilisateurs/edit";
import PdfPageWrapper from "pages/Offer/PdfPageWrapper";
import Locations from "pages/Locations";
import Contacts from "pages/Contacts";
import Calendrier from "pages/Calendrier";
import Utilisateurs from "pages/Utilisateurs";
import Login from "pages/Utilisateurs/Login";
import Logout from "pages/Utilisateurs/Logout";
import DeleteAll from "pages/Data/DeleteAll";
import Synchronizer from "pages/Data/Synchronizer";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/scss/theme.scss";
import "../assets/css/style.css";

/**
 * Main app component
 */
class App extends Component {
  render() {
    return (
      <div className="app">
        <div id="wrapper">          
            <Switch>
              <PrivateRoute path="/ventes" component={Ventes} />
              <PrivateRoute path="/locations" component={Locations} />
              <PrivateRoute path="/offer/:id" component={Offer} />
              <PrivateRoute path="/pdf/:id/:lang" component={PdfPageWrapper} />
              <PrivateRoute path="/logout" component={Logout} />
              <PrivateRoute path="/contacts" component={Contacts} />
              <PrivateRoute path="/contact/:id" component={ContactDetail} />
              <PrivateRoute path="/calendrier" component={Calendrier} />
              <PrivateRoute path="/events/:id" component={Event} />
              <PrivateRoute path="/utilisateurs" component={Utilisateurs} />
              <PrivateRoute path="/users/edit/:id" component={UserEdit} />
              <PrivateRoute path="/delete-all" component={DeleteAll} />
              <PrivateRoute path="/synchronize" component={Synchronizer} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Redirect from="*" to="/ventes" />
            </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.default.user,
});

export default connect(mapStateToProps)(App);
