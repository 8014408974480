// getVisibleContacts
export default (contacts, { name }) => {
  return contacts.filter(contact => {
    const fullName = contact.co_firstname + ' ' + contact.co_name
    const nameMatch =
      fullName.toLowerCase().includes(name.toLowerCase());

    return nameMatch
  }).sort((contact1, contact2) => {
    return contact1.co_firstname < contact2.co_firstname ? -1 : 1
  });
}