import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import * as storage from 'redux-storage'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import * as reducers from 'redux/reducers';
import createEngine from 'redux-storage-engine-indexed-db';
import debounce from 'redux-storage-decorator-debounce';
import { BrowserRouter, withRouter, useLocation } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';
import App from 'components/App';

const reducer = storage.reducer(combineReducers(reducers));
let engine = createEngine('miells-redux');
engine = debounce(engine, 1500);
const middleware = storage.createMiddleware(engine);
const createStoreWithMiddleware = applyMiddleware(thunk, middleware)(createStore);
const store = createStoreWithMiddleware(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const load = storage.createLoader(engine);

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}

// Load state from indexed-db
load(store)
    /*.then((newState) => console.log('Loaded state:', newState))
    .catch(() => console.log('Failed to load previous state'));*/

const ScrollToTop = withRouter(_ScrollToTop)

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <Provider store={store}>
        <App />
      </Provider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
