import React, { Component, Fragment } from "react";
import { Button, Dropdown } from 'reactstrap';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import moment from 'moment';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import ICalendarLink from "react-icalendar-link";

class Event extends Component {
  state = {
    icsEvent: null,
  };


  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.setIcsEvent(this.props.event)
    }
  }

  componentDidMount() {
    let { event } = this.props;
    if (event) {
      this.setIcsEvent(event)
    }
  }

  setIcsEvent(event) {
    const startDatetime = moment(event.start);
    const endDatetime = moment(event.start);
    const duration = moment.duration(endDatetime.diff(startDatetime)).asHours()

    this.setState({
      icsEvent: {
        description: event.description ? event.description : '',
        duration: duration,
        endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
        startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
        location: event.url,
        title: event.title,
      }
    })
  }

  render() {
    let { icsEvent } = this.state;
    let { event } = this.props;
    let iOSEvent = JSON.parse(JSON.stringify(icsEvent));
    const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (event) {
      const startDatetime = moment(event.start);
      const endDatetime = moment(event.end);
      
      iOSEvent = {
        title: event.title,
        description: event.description,
        startTime: startDatetime.format('YYYY-MM-DDTHH:mm:ssZ'),
        endTime: endDatetime.format('YYYY-MM-DDTHH:mm:ssZ'),
        location: event.url,
      }
    }

    return (
      <div className="event">
        { isiOS && iOSEvent
          ? <ICalendarLink event={iOSEvent} className="btn btn-danger btn-block mt-5">
            Ajouter au calendrier
          </ICalendarLink>
         : <Fragment>
            event && icsEvent
              ? 
              <AddToCalendarDropdown
                linkProps={{ className: "linkStyles" }}
                buttonText="Ajouter au calendrier"
                event={icsEvent}
              /> 
              : <Fragment></Fragment>
              </Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  event: _.find(
    state.default.events,
    (event) => event.id == ownProps.match.params.id
  ),
});

export default withRouter(connect(mapStateToProps)(Event));
