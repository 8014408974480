// getVisibleUsers
export default (users, { name }) => {
  return users.filter(user => {
    const fullName = user.co_firstname + ' ' + user.co_name
    const nameMatch =
      fullName.toLowerCase().includes(name.toLowerCase());

    return nameMatch
  }).sort((user1, user2) => {
    return user1.co_firstname < user2.co_firstname ? -1 : 1
  });
}