import _ from "lodash";

export function getUserName(co_id, users) {
  const user = _.find(users, (user) => user.co_id === co_id);

  if (user === undefined) {
    return "-";
  }

  return user.co_firstname + " " + user.co_name;
}
