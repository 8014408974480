import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import missing from 'assets/img/missing.jpg';
import { formatPrice } from 'helpers/formatPrice'
import { getImageUrl } from 'helpers/getImageUrl'

export default class OfferCard extends Component {
  getImage() {
    const { offer } = this.props;

    if (offer.images.length > 0) {
      return getImageUrl(offer.images[0]);
    }

    return missing;
    //return offer.blobImages.length > 0 ? "data:image/png;base64," + offer.blobImages[0] : missing
  }

  render() {
    const { offer } = this.props;

    return (
      <div className="card">
        <Fragment>
          <NavLink to={`offer/${offer.id}`} className="card-img-top">
            <img
              src={this.getImage()}
              className="d-block w-100"
              alt={offer.title}
            />
          </NavLink>
        </Fragment>

        <div className="card-body">
          <p className="text-success text-uppercase font-size-12 mb-2">
            {offer.of_sell ? "Vente" : ""}
            {offer.of_rent && offer.of_sell ? " / " : ""}
            {offer.of_rent ? "Location" : ""}
          </p>
          <h5>
            <NavLink to={`offer/${offer.id}`} className="text-dark">
              {offer.of_ref} / {offer.title}
            </NavLink>
          </h5>
        </div>
        <div className="card-body border-top">
          <div className="row align-items-center">
            <div className="col-sm-auto">
              <ul className="list-inline mb-0">
                <li className="list-inline-item pr-2">
                  {offer.price ? formatPrice(offer.price) : "PRIX SUR DEMANDE"}
                </li>
                <li className="list-inline-item pr-2">
                  <i className="uil uil-arrows-h-alt mr-1"></i>{" "}
                  {offer.of_surface} m<sup>2</sup>
                </li>
                <li className="list-inline-item pr-2">
                  <i className="uil uil-map-marker mr-1"></i> {offer.city}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}