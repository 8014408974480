import _ from "lodash";

export function groupOffers(results, state) {
	let offers = {
		toAdd: [],
		toRemove: [],
	}

	if (results.length > 0) {
		// Get offers to add
		results.map((result) => {
			let found = state.default.offers.find(function (offer) {
				if (offer.id) {
					let offerId = offer.id.replace("-L", "").replace("-V", "");

					if (offerId == result.of_id) {
						if (!offer.id.includes("-L") && !offer.id.includes("-V")) {
							let change = !_.isEqual(offer, result);

							if (change) {
								offers.toRemove.push(result);
								return undefined;
							}
						}
					}

				}

				return offer;
			});

			if (found === undefined) {
				let offer = {
					id: result.of_id,
					title: result.of_name_fr,
					of_name: result.of_name,
					of_text: result.of_text,
					of_name_fr: result.of_name_fr,
					of_name_en: result.of_name_en,
					of_name_ru: result.of_name_ru,
					of_name_it: result.of_name_it,
					of_text_fr: result.of_text_fr,
					of_text_en: result.of_text_en,
					of_text_ru: result.of_text_ru,
					of_text_it: result.of_text_it,
					description: result.of_summary_fr,
					price: result.of_rent ? result.of_rent_sum : result.of_sell_sum,
					of_id: result.of_id,
					of_rent: result.of_rent,
					of_rent_honoraireacquereur: result.of_rent_honoraireacquereur,
					of_rent_honorairebailleur: result.of_rent_honorairebailleur,
					of_rent_sum: result.of_rent_sum,
					of_rent_sum_year: result.of_rent_sum_year,
					of_rent_charge: result.of_rent_charge,
					of_sell: result.of_sell,
					of_sell_sum: result.of_sell_sum,
					of_sell_honorairevendeur: result.of_sell_honorairevendeur,
					of_sell_honoraireacquereur: result.of_sell_honoraireacquereur,
					of_sell_fraisdenotaire: result.of_sell_fraisdenotaire,
					of_type: result.of_type,
					of_status: result.of_status,
					of_immotools: result.of_immotools,
					of_web: result.of_web,
					of_ci: result.of_ci,
					bu_id: result.bu_id ? {
						bu_id: result.bu_id.bu_id,
						bu_name: result.bu_id.bu_name,
						bu_address1: result.bu_id.bu_address1,
						bu_address2: result.bu_id.bu_address2,
						bu_zip: result.bu_id.bu_zip,
						bu_district: result.bu_id.bu_district,
						bu_city: result.bu_id.bu_city,
						bu_country: result.bu_id.bu_country,
					} : null,
					bu_district: result.bu_district,
					of_agency_txt: result.of_agency_txt,
					of_access: result.of_access,
					of_city: result.bu_id ? result.bu_id.bu_city : "",
					of_disponibility: result.of_disponibility,
					of_nbpieces: result.of_nbpieces,
					of_nbcaves: result.of_nbcaves,
					of_nbparkings: result.of_nbparkings,
					co_id_owner: result.co_id_owner,
					of_mandat: result.of_mandat,
					of_nbrooms: result.of_nbrooms,
					of_ref: result.of_ref,
					of_surface: result.of_surface,
					of_surface_livable: result.of_surface_livable,
					of_surface_approximate: result.of_surface_approximate,
					of_surface_terrace: result.of_surface_terrace,
					of_etage: result.of_etage,
					of_floor: result.of_floor,
					of_commitment_date: result.of_commitment_date,
					co_id_commercial1: result.co_id_commercial1,
					co_id_commercial2: result.co_id_commercial2,
					of_notes_customer: result.of_notes_customer,
					of_notes_private: result.of_notes_private,
					of_notes_private2: result.of_notes_private2,
					images: [],
					nbImages: result.images.length,
					blobImages: [],
				};

				if (offer.of_rent === offer.of_sell) {
					let offerRent = JSON.parse(JSON.stringify(offer));
					offerRent.id = offer.of_id + "-L";
					offerRent.of_ref = offer.of_ref + "-L";
					offerRent.price = offer.of_rent_sum;
					offerRent.of_rent = true;
					offerRent.of_sell = false;

					let offerSell = JSON.parse(JSON.stringify(offer));
					offerSell.id += offer.of_id + "-V";
					offerSell.of_ref = offer.of_ref + "-V";
					offerSell.price = offer.of_sell_sum;
					offerSell.of_rent = false;
					offerSell.of_sell = true;

					offers.toAdd.push(offerRent);
					offers.toAdd.push(offerSell);
				}

				offers.toAdd.push(offer);
			}

			return result;
		});
	}

	return offers;
}
