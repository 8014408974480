import _ from "lodash";

export function getContactName(co_id, contacts) {
  const contact = _.find(contacts, (contact) => contact.co_id === co_id);

  if (contact === undefined) {
    return "-";
  }

  return contact.co_firstname + " " + contact.co_name;
}
