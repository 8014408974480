import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import missing from "assets/img/missing.jpg";
import * as FeatherIcon from "react-feather";
import { getBuildingName } from 'helpers/getBuildingName';
import { getContactName } from 'helpers/getContactName';
import { getUserName } from 'helpers/getUserName';
import { getOfferDisponibility } from 'helpers/getOfferDisponibility';
import { getOfferType } from 'helpers/getOfferType';
import { formatPrice } from 'helpers/formatPrice';
import { formatDate } from 'helpers/formatDate';
import { getImageUrl } from "helpers/getImageUrl";
import _ from "lodash";
import { formatAddress } from "helpers/formatAddress";
import OfferCarousel from 'components/Offer/OfferCarousel'

class Offer extends Component {
  state = {
    isOpen: false,
    isOpenInfosCommerciales: false,
    base64data: null,
    carouselItems: [],
    imageLoading: false,
  };

  componentDidMount() {
    this.showImage()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offer !== this.props.offer) {
      this.showImage()
    }
  }

  setIsOpen = isOpen => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  setIsOpenInfosCommerciales = isOpen => {
    this.setState({ isOpenInfosCommerciales: !this.state.isOpenInfosCommerciales });
  }

  openLinkInNewTab = (e) => {
    e.preventDefault();

    if (e.target.tagName.toLowerCase() === 'a') {
        window.open( e.target.href );
    }
  }

  showImage() {
    const { offer } = this.props;

    console.log(offer);

    if (offer && offer.images.length > 0) {
      this.setState({imageLoading: true});

      offer.images.map(image => {
        this.setState((state) => {
          const carouselItems = [
            ...state.carouselItems,
            {
              src: getImageUrl(image),
            },
          ];

          return {
            carouselItems,
          };
        });
        
        /*offer.blobImages.map(image => {
        this.setState((state) => {
          const carouselItems = [
            ...state.carouselItems,
            {
              src: "data:image/png;base64," + image,
            },
          ];

          return {
            carouselItems,
          };
        });*/

        return image
      })

      this.setState({imageLoading: false});
    }
  }

  render() {
    let { offer, contacts, preferences } = this.props;
    const { buildings } = this.props.preferences;
    document.body.classList.remove('sidebar-enable');
    const { isOpen, isOpenInfosCommerciales } = this.state;
    const toggle = () => this.setIsOpen(!isOpen);
    const toggleInfosCommerciales = () => this.setIsOpenInfosCommerciales(!isOpenInfosCommerciales);

    return (
      <div>
        {offer ? (
          <div>
            <div className="row page-title">
              <h4 className="mb-1 mt-0 ml-3">
                <FeatherIcon.ChevronLeft className="icon-xs" />{" "}
                <button
                  onClick={this.props.history.goBack}
                  className="btn btn-link btn-back"
                >
                  Retour
                </button>
              </h4>
            </div>
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-body p-0">
                    <h6 className="card-title border-bottom p-3 mb-0 header-title">
                      {offer.of_ref} / {offer.title}
                      <div className="badge badge-success font-size-13 font-weight-normal ml-1">
                        {offer.of_sell ? "Vente" : ""}
                        {offer.of_rent && offer.of_sell ? " / " : ""}
                        {offer.of_rent ? "Location" : ""}
                      </div>
                    </h6>
                    <div className="row py-1">
                      <div className="col-xl-2 col-sm-6">
                        <div className="media p-3">
                          <FeatherIcon.CreditCard className="align-self-center icon-dual icon-lg mr-4" />
                          <div className="media-body">
                            <h4 className="mt-0 mb-0">
                              {offer.price
                                ? formatPrice(offer.price)
                                : "PRIX SUR DEMANDE"}
                            </h4>
                            <span className="text-muted font-size-13">
                              Prix
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <div className="media p-3">
                          <FeatherIcon.Type className="align-self-center icon-dual icon-lg mr-4" />
                          <div className="media-body">
                            <h4 className="mt-0 mb-0">
                              {getOfferType(offer.of_type, preferences)}
                            </h4>
                            <span className="text-muted">Type de bien</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <div className="media p-3">
                          <FeatherIcon.Box className="align-self-center icon-dual icon-lg mr-4" />
                          <div className="media-body">
                            <h4 className="mt-0 mb-0">{offer.of_nbpieces}</h4>
                            <span className="text-muted">Pièces</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <div className="media p-3">
                          <FeatherIcon.Smartphone className="align-self-center icon-dual icon-lg mr-4" />
                          <div className="media-body">
                            <h4 className="mt-0 mb-0">{offer.of_nbrooms}</h4>
                            <span className="text-muted">Chambres</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <div className="media p-3">
                          <FeatherIcon.Columns className="align-self-center icon-dual icon-lg mr-4" />
                          <div className="media-body">
                            <h4 className="mt-0 mb-0">
                              {offer.bu_id
                                ? getBuildingName(offer.bu_id.bu_id, buildings)
                                : "-"}
                            </h4>
                            <span className="text-muted">Immeuble</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <div className="media p-3">
                          <FeatherIcon.Map className="align-self-center icon-dual icon-lg mr-4" />
                          <div className="media-body">
                            <h4 className="mt-0 mb-0">
                              {offer.bu_district ? offer.bu_district : "-"}
                            </h4>
                            <span className="text-muted">Quartier</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {this.state.imageLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: 274 }}
                  >
                    <div className="spinner-grow text-danger">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    {this.state.carouselItems.length === 0 ? (
                      <img
                        src={missing}
                        className="img-fluid mb-4"
                        alt={offer.title}
                      />
                    ) : (
                      <OfferCarousel
                        items={this.state.carouselItems}
                      ></OfferCarousel>
                    )}
                  </Fragment>
                )}
              </div>
              <div className="col-md-6">
                <div className="card mt-4 mt-lg-0">
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">Les détails</h4>
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Agence</th>
                            <td>{offer.of_agency_txt}</td>
                          </tr>
                          <tr>
                            <th scope="row">Type de bien</th>
                            <td>{getOfferType(offer.of_type, preferences)}</td>
                          </tr>
                          <tr>
                            <th scope="row">Référence</th>
                            <td>{offer.of_ref}</td>
                          </tr>
                          <tr>
                            <th scope="row">Surf. totale</th>
                            <td>{offer.of_surface} m2</td>
                          </tr>
                          <tr>
                            <th scope="row">Surf. habitable</th>
                            <td>{offer.of_surface_livable} m2</td>
                          </tr>
                          <tr>
                            <th scope="row">Surf. approximative</th>
                            <td>
                              {offer.of_surface_approximate ? "Oui" : "Non"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Surf. terasse</th>
                            <td>
                              {offer.of_surface_terrace > 0
                                ? offer.of_surface_terrace + " m2"
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Étage</th>
                            <td>{offer.of_etage}</td>
                          </tr>
                          <tr>
                            <th scope="row">Nb pièces</th>
                            <td>{offer.of_nbpieces}</td>
                          </tr>
                          <tr>
                            <th scope="row">Nb chambres</th>
                            <td>
                              {offer.of_nbrooms > 0 ? offer.of_nbrooms : ""}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Nb caves</th>
                            <td>{offer.of_nbcaves}</td>
                          </tr>
                          <tr>
                            <th scope="row">Nb parkings</th>
                            <td>{offer.of_nbparkings}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4">
                {offer.of_rent ? (
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mt-0 mb-3">À la location</h4>
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Loyer mensuel</th>
                              <td>{formatPrice(offer.price)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Loyer annuel</th>
                              <td>{formatPrice(offer.of_rent_sum_year)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Charges mensuelles</th>
                              <td>{formatPrice(offer.of_rent_charge)}</td>
                            </tr>
                            <tr>
                              <th scope="row">
                                Honoraires à charge du preneur TTC
                              </th>
                              <td>
                                {formatPrice(offer.of_rent_honorairebailleur)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mt-0 mb-3">À la vente</h4>
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Prix de présentation</th>
                              <td>{formatPrice(offer.price)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Prix net vendeur</th>
                              <td>{formatPrice(offer.of_sell_sum)}</td>
                            </tr>
                            <tr>
                              <th scope="row">
                                Honoraires à la charge du vendeur TTC
                              </th>
                              <td>
                                {formatPrice(offer.of_sell_honorairevendeur)}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                Honoraires à la de l'acquéreur TTC
                              </th>
                              <td>
                                {formatPrice(offer.of_sell_honoraireacquereur)}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Frais de notaire</th>
                              <td>
                                {formatPrice(offer.of_sell_fraisdenotaire)}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Offre > Date signature prévue</th>
                              <td>{formatDate(offer.of_commitment_date)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">
                      Statut et visibilité
                    </h4>
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Status</th>
                            <td>{offer.of_status ? "Actif" : "Inactif"}</td>
                          </tr>
                          <tr>
                            <th scope="row">Sur internet</th>
                            <td>{offer.of_web ? "Oui" : "Non"}</td>
                          </tr>
                          <tr>
                            <th scope="row">Sur CI</th>
                            <td>{offer.of_ci ? "Oui" : "Non"}</td>
                          </tr>
                          <tr>
                            <th scope="row">Disponibilité</th>
                            <td>
                              {getOfferDisponibility(
                                offer.of_disponibility,
                                preferences
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Adresse du bâtiment</th>
                            <td>
                              {offer.bu_id
                                ? formatAddress(offer.bu_id, "bu_")
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">
                      <button
                        className="btn btn-link lead"
                        onClick={toggleInfosCommerciales}
                      >
                        Informations commerciales... et consignes de visites
                      </button>
                    </h4>

                    <Collapse isOpen={isOpenInfosCommerciales}>
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Chef de produit 1</th>
                              <td>
                                {getUserName(offer.co_id_commercial1, contacts)}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Chef de produit 2</th>
                              <td>
                                {getUserName(offer.co_id_commercial2, contacts)}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Consigne de visite</th>
                              <td>{offer.of_access ? offer.of_access : "-"}</td>
                            </tr>
                            <tr>
                              <th scope="row">Notes privées</th>
                              <td>
                                {offer.of_notes_customer ? (
                                  <p>{offer.of_notes_customer}</p>
                                ) : null}
                                {offer.of_notes_private ? (
                                  <p>{offer.of_notes_private}</p>
                                ) : null}
                                {offer.of_notes_private2 ? (
                                  <p>{offer.of_notes_private2}</p>
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <h4 className="header-title mt-0 mb-3">
                        Le propriétaire et le mandat
                      </h4>
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Propriétaire</th>
                              <td>
                                {getContactName(offer.co_id_owner, contacts)}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Mandat</th>
                              <td>{offer.of_mandat}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <Button
                      color="primary"
                      onClick={toggle}
                      className="btn-block"
                    >
                      Fiche complète
                    </Button>
                    <Collapse isOpen={isOpen}>
                      <Card>
                        <CardBody>
                          {offer.of_name_fr.trim() !== "" ? (
                            <NavLink
                              to={`/pdf/${offer.id}/fr`}
                              className="btn btn-outline-primary mb-2 d-block"
                            >
                              Français
                            </NavLink>
                          ) : null}
                          {offer.of_name_en.trim() !== "" ? (
                            <NavLink
                              to={`/pdf/${offer.id}/en`}
                              className="btn btn-outline-primary mb-2 d-block"
                            >
                              Anglais
                            </NavLink>
                          ) : null}
                          {offer.of_name_it.trim() !== "" ? (
                            <NavLink
                              to={`/pdf/${offer.id}/it`}
                              className="btn btn-outline-primary mb-2 d-block"
                            >
                              Italien
                            </NavLink>
                          ) : null}
                          {offer.of_name_ru.trim() !== "" ? (
                            <NavLink
                              to={`/pdf/${offer.id}/ru`}
                              className="btn btn-outline-primary mb-2 d-block"
                            >
                              Russe
                            </NavLink>
                          ) : null}
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  offer: _.find(
    state.default.offers,
    (offer) => offer.id === ownProps.match.params.id
  ),
  contacts: state.default.contacts,
  preferences: state.default.preferences,
});

export default withRouter(connect(mapStateToProps)(Offer));
