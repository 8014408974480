import _ from "lodash";

export function getOfferDisponibility(of_disponibility, preferences) {
  if (preferences.items) {
    const disponibility = _.find(
      preferences.items_of_disponibility,
      (disponibility) => disponibility.value === of_disponibility
    );

    if (disponibility === undefined) {
      return "-";
    }

    return disponibility.text;
  }

  return "-";
}
