import { LOAD } from "redux-storage";

const initialState = {
    reduxStorageLoaded: false,
    lastdelta: -1,
    offersTotal: [],
    offers: [],
    contacts: [],
    events: [],
    users: [],
    preferences: [],
    images: [],
    showProgressBar: false,
    progressBarText: 'Synchronisation en cours...',
    percentage: 0,
    filters: {
        offers: { 
            reference: '',
            offerType: '',
            bu_id: '',
            budgetMin: '',
            budgetMax: '',
            district: '',
            nbPieces: '',
            surfaceMin: '',
            surfaceMax: '',
            monaco: false,
            france: false,
            immotools: false,
            cellar: false,
        },
        contacts: {
            name: ''
        },
        users: {
            name: ''
        }
    },
}

const reducer = (state = initialState, action) => {
    let filters = { ...state.filters }

    switch (action.type) {
        case LOAD:
            return {
                ...state,
                reduxStorageLoaded: true,
            };
        case 'ADD_OFFER':
            let filteredOffers = state.offers.filter(like => like.id !== action.offer.id);

            return {
                ...state,
                offers: [...filteredOffers, action.offer]
            }
        case 'REMOVE_OFFER':
            return {
                ...state,
                offers: state.offers.filter(like => like.id !== action.offer.id)
            }
        case 'GET_OFFER':
            return state.offers.filter(like => like.id === action.id);
        case 'FILTER_REFERENCE':
            filters.offers.reference = action.reference
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_TYPE':
            filters.offers.offerType = action.offerType
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_BUILDING':
            filters.offers.bu_id = action.bu_id
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_DISTRICT':
            filters.offers.district = action.district
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_NB_PIECES':
            filters.offers.nbPieces = action.nbPieces
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_BUDGET_MIN':
            filters.offers.budgetMin = action.budgetMin
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_BUDGET_MAX':
            filters.offers.budgetMax = action.budgetMax
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_SURFACE_MIN':
            filters.offers.surfaceMin = action.surfaceMin
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_SURFACE_MAX':
            filters.offers.surfaceMax = action.surfaceMax
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_MONACO':
            filters.offers.monaco = action.monaco
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_FRANCE':
            filters.offers.france = action.france
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_IMMOTOOLS':
            filters.offers.immotools = action.immotools
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_CELLAR':
            filters.offers.cellar = action.cellar
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_CONTACT_NAME':
            filters.contacts.name = action.name
            return {
                ...state,
                filters: filters
            };
        case 'FILTER_USER_NAME':
            filters.users.name = action.name
            return {
                ...state,
                filters: filters
            };
        case 'UPDATE_CONTACTS':
            return {
                ...state,
                contacts: action.contacts
            }
        case 'UPDATE_EVENTS':
            return {
                ...state,
                events: action.events
            }
        case 'SET_OFFERS':
            return {
                ...state,
                offers: action.offers
            }
        case 'UPDATE_USERS':
            return {
                ...state,
                users: action.users
            }
        case 'UPDATE_PREFERENCES':
            return {
                ...state,
                preferences: action.preferences
            }
        case 'UPDATE_LAST_DELTA':
            return {
                ...state,
                lastdelta: action.lastdelta
            }
        case 'UPDATE_ITEMS_COUNT':
            return {
                ...state,
                itemsCount: action.itemsCount
            }
        case 'UPDATE_PROGRESSBAR_TEXT':
            return {
                ...state,
                progressBarText: action.progressBarText
            }
        case 'UPDATE_PERCENTAGE':
            return {
                ...state,
                percentage: state.percentage + action.percentage
            }
        case 'SET_PERCENTAGE':
            return {
                ...state,
                percentage: action.percentage
            }
        case 'SET_OFFERS_TOTAL':
            return {
                ...state,
                offersTotal: action.offersTotal
            }
        case 'UPDATE_SHOW_PROGRESS_BAR':
            return {
                ...state,
                showProgressBar: action.showProgressBar
            }
        case 'RESET_FILTERS':
            return {
                ...state,
                filters: {
                    offers: { 
                        reference: '',
                        offerType: '',
                        bu_id: '',
                        budgetMin: '',
                        budgetMax: '',
                        district: '',
                        nbPieces: '',
                        surfaceMin: '',
                        surfaceMax: '',
                        monaco: false,
                        france: false,
                        immotools: false,
                        cellar: false,
                    },
                    contacts: {
                        name: ''
                    },
                    users: {
                        name: ''
                    }
                }
            };
        case 'RESET_STATE':
            return initialState
        default:
            return state
    }
}

export default reducer
