import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ContactCard from "components/Contact/ContactCard";
import getVisibleContacts from "redux/selectors/getVisibleContacts";
import { filterContactName } from "redux/actions/filters";
import InfiniteScroll from "react-infinite-scroll-component";

class Contacts extends Component {
  state = {
    items: [],
    paginate: 60,
    lastIndex: 60,
    hasMore: true
  };
  
  componentDidUpdate(prevProps) {
    if (prevProps.contacts !== this.props.contacts) {
      this.setItems(this.props.contacts)
    }
  }

  componentDidMount() {
    let { contacts } = this.props;
    if (contacts) {
      this.setItems(contacts)
    }
  }

  setItems(items) {
    this.setState({
      items: items.slice(0, this.state.lastIndex),
    });
  }

  fetchMoreData = () => {
    if (this.state.items.length >= this.props.contacts.length) {
      this.setState({ hasMore: false });
      return;
    }
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(this.props.contacts.slice(this.state.lastIndex, this.state.lastIndex + this.state.paginate)),
        lastIndex: this.state.lastIndex + this.state.paginate
      });
    }, 500);
  };

  render() {
    document.body.classList.remove("sidebar-enable");
    let { items, hasMore, paginate } = this.state;

    if (items.length < paginate) {
      hasMore = false
    }

    return (
      <Fragment>
        <div className="row page-title align-items-center">
          <div className="col-md-9">
            <h4 className="mb-1 mt-0">Contacts</h4>
          </div>
          <div className="col-md-3">
            <div className="mt-4 mt-md-0">
              <input
                type="text"
                className="form-control"
                placeholder="Rechercher"
                value={this.props.filters.name}
                onChange={(e) => {
                  this.props.dispatch(filterContactName(e.target.value));
                }}
              />
            </div>
          </div>
        </div>
        {items.length > 0 ? (
          <Fragment>
            <InfiniteScroll style={{ overflow: "visible" }}
              dataLength={items.length} //This is important field to render the next data
              next={this.fetchMoreData}
              hasMore={hasMore}
              loader={<div className="text-center"><div className="spinner-border text-primary">
                <span className="sr-only">Loading...</span>
              </div></div>}
              endMessage=''
            >
              <div className="row">
                {this.state.items.map((contact) => (
                  <div className="col-xl-3 col-lg-6" key={contact.co_id}>
                    <ContactCard contact={contact} />
                  </div>
                ))}
              </div>
              </InfiniteScroll>
           </Fragment>
        ) : (
          ""
        )}
        </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.default.filters.contacts,
  contacts: getVisibleContacts(
    state.default.contacts,
    state.default.filters.contacts
  ),
});

export default connect(mapStateToProps)(Contacts);
